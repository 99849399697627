import * as React from 'react';
import NotFound from "../404/NotFound";
import ScheduledEvents from "./Components/ScheduledEvents";
import Calendar from "./Components/Calendar";
import Test from "./Test";
import EventTypesGroup from "./Components/EventTypesGroup";
import '../../style/DashboardMain.css'
import WorkingDays from "./Components/WorkingDays";
import NonWorkingDays from "./Components/NonWorkingDays";
import ManageCalendars from "./Components/ManageCalendars";
import WorkingDaysNew from "./Components/WorkingDaysNew";
import ManageImages from "./Components/ManageImages";
import ManageMicrosoftCalendars from "./Components/ManageMicrosoftCalendars";
import NonWorkingDaysCalendar from "./Components/NonWorkingDaysCalendar";
import CalendarWithDetails from "./Components/CalendarWithDetails";
import {useState} from "react";
import AdminPanel from "./Components/AdminPanel";
import SuperAdminPanel from "./Components/SuperAdminPanel";

export default function Main( {selected, eventGroup, editEventGroup, logoutUser, allowedEmails, setAllowedEmails, userEmail, userName, userAvatar, organization, setOrganization, role, allAllowedEmails, setAllAllowedEmails, rerender} ){

    const [allGroups, setAllGroups] = useState([]);

    switch (selected) {
        case 0: return (<EventTypesGroup editEventGroup={editEventGroup} setAllGroups={setAllGroups} userName={userName} userAvatar={userAvatar}/>);
        //case 1: return (<WorkingDays/>);
        case 1: return (<WorkingDaysNew eventGroup={eventGroup} allGroups={allGroups} editEventGroup={editEventGroup}/>);
        //case 2: return (<NonWorkingDays/>);
        //case 2: return (<NonWorkingDaysCalendar/>);
        case 2: return (<CalendarWithDetails/>);
        case 3: return (<ManageCalendars logoutUser={logoutUser}/>);
        case 4: return (<ManageImages/>);
        case 5: return (<ManageMicrosoftCalendars logoutUser={logoutUser}/>);
        case 6: return (<AdminPanel organization={organization} role={role} userEmail={userEmail} allowedEmails={allowedEmails} setAllowedEmails={setAllowedEmails} rerender={rerender}/>);
        case 7: return (<SuperAdminPanel organization={organization} setOrganization={setOrganization} role={role} userEmail={userEmail} allowedEmails={allAllowedEmails} setAllowedEmails={setAllAllowedEmails} rerender={rerender}/>);
        default: return (<NotFound/>);
    }
}
